<template>
  <v-card
    dark
    flat
    class="rounded-t-0"
    v-if="item"
    :to="{ name: 'Gallery', params: { gallery: item.name } }"
  >
    <GalleryThumb
      :gallery="item.name"
      :image="item.image"
      class="white--text align-end"
    >
      <v-card-title>{{ item.title }} </v-card-title>

      <v-card-subtitle class="d-flex align-center justify-center"
        ><span
          ><template v-if="item.date"
            >{{ formatDate(item.date, true) }},
          </template>
          {{ item.count }} Bilder</span
        ><v-spacer />
        <v-tooltip bottom>
          <template
            v-slot:activator="{ on, attrs }"
            v-if="item.visibility == 'teacher'"
          >
            <v-icon color="white" v-bind="attrs" v-on="on"
              >mdi-account-school</v-icon
            >
          </template>
          <span>nur für LK sichtbar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template
            v-slot:activator="{ on, attrs }"
            v-if="item.visibility == 'admin'"
          >
            <v-icon color="white" v-bind="attrs" v-on="on"
              >mdi-account-tie</v-icon
            >
          </template>
          <span>nur für Admins sichtbar</span>
        </v-tooltip>
      </v-card-subtitle>
    </GalleryThumb>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import GalleryThumb from "@/views/Gallery/components/GalleryThumb.vue";
import { formatDate } from "common/utils/date";
export default defineComponent({
  components: { GalleryThumb },
  data() {
    return {
      loading: false,
      item: false,
    };
  },
  computed: {},
  methods: {
    formatDate,
    async fetchData() {
      this.loading = true;
      const galleries = await this.apiList({
        resource: "gallery/gallery",
        query: ``,
      });
      const items = galleries.sort((a, b) =>
        a.date > b.date ? -1 : a.date < b.date ? 1 : 0
      );

      this.loading = false;
      if (items.length > 0) {
        this.$emit("loaded");
        this.item = items[0];
      }
    },
  },
  async created() {
    this.fetchData();
  },
});
</script>
